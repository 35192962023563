import React, {useRef, useEffect, useCallback} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {Link, useLocation} from "react-router-dom";
import {routesConfig} from "../config/routes";
import DelayLink from "./DelayLink";
import {pageTransitionDelay, pageTransitionDuration} from "../transitions/TransitionConstants";

// Overlay container animation
const overlayVariants = {
    // Overlay enters the scene as hidden
    hidden: {
    },
    // Overlay becomes visible
    visible: {
    },
    // Overlay exiting the scene
    exit: {
    },
}

// Overlay background animation
const overlayBackgroundVariants = {
    // Overlay enters the scene as hidden
    hidden: {
        width: 0,
        transition: {
            ease: 'easeInOut'
        }
    },
    // Overlay becomes visible
    visible: {
        width: "100%",
        transition: {
            duration: pageTransitionDelay,
            ease: 'easeInOut'
        }
    },
    // Overlay exiting the scene
    exit: {
        width: 0,
        transition: {
            ease: 'easeInOut',
            delay: pageTransitionDelay,
            duration: pageTransitionDuration
        }
    },
}

// Overlay menu animation
const menuItemVariants = {
    // While the overlay is closed
    overlayClosed: {
        opacity: 0
    },
    // On overlay opens
    overlayOpened: {
        opacity: 1
    }
};

// Overlay menu item animation
const menuRevealMenuItemsVariants = {
    // On overlay closes
    overlayClosed: {
        transition: {
            staggerChildren: 0.1,
            staggerDirection: -1
        }
    },
    // On overlay opened stagger through children elements of menu
    overlayOpened: {
        transition: {
            delayChildren: pageTransitionDelay / 2,
            staggerChildren: 0.1,
            staggerDirection: 1
        }
    }
};

const Overlay = ({overlayVisible, setShowOverlay}) => {
    const overlay = useRef(null);
    const trigger = useRef(null);
    const location = useLocation();

    // Escape
    const escapeFunction = useCallback((event) => {
        if (event.key === "Escape") {
            setShowOverlay(false);
        }
    }, []);

    // Trigger
    function triggerClick() {
        setShowOverlay((overlayVisible) => !overlayVisible);
    }

    // On press escape
    useEffect(() => {
        document.addEventListener("keydown", escapeFunction, false);

        return () => {
            document.removeEventListener("keydown", escapeFunction, false);
        };
    }, [escapeFunction]);

    // On click outside the overlay or the trigger
    useEffect(() => {
        function handleClickOutside(event) {
            if ((overlay.current && !overlay.current.contains(event.target)) && (trigger.current && !trigger.current.contains(event.target))) {
                setShowOverlay(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [overlay]);

    const groupRoutes = (routes) => {
        return routes.reduce((groups, route) => {
            if (route.showInMenu) {
                if (!groups[route.group]) {
                    groups[route.group] = [];
                }
                groups[route.group].push(route);
            }
            return groups;
        }, {});
    };

    const groupedRoutes = groupRoutes(routesConfig);

    const portfolioRoutes = groupedRoutes.portfolio || [];
    const blogRoutes = groupedRoutes.blog?.filter(route => route.name !== "Post") || [];
    const clicheTalesRoutes = groupedRoutes["cliche-tales"] || [];

    return (
        <>
            <a
                className={`site-frame site-frame__block site-frame__block--right navigation-trigger ${overlayVisible ? "active" : ""}`}
                onClick={triggerClick}
                ref={trigger}
            >
                {overlayVisible ? (
                    <>
                        <span className="menu-text">Close</span>
                        <span className="menu-text menu-text--hidden">Menu</span>
                    </>
                ) : (
                    <>
                        <span className="menu-text menu-text--hidden">Close</span>
                        <span className="menu-text">Menu</span>
                    </>
                )
                }
                <span className="menu-icon"/>
            </a>
            <AnimatePresence>
                {overlayVisible && (
                    <motion.aside
                        ref={overlay}
                        className="overlay"
                        variants={overlayVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                    >
                        <motion.div
                            className="navigation"
                            initial="overlayClosed"
                            animate="overlayOpened"
                            exit="overlayClosed"
                            variants={menuRevealMenuItemsVariants}
                        >
                            {portfolioRoutes.map(({ name, path }, index) => (
                                <motion.div
                                    key={index}
                                    variants={menuItemVariants}
                                    className="navigation__item"
                                >
                                    <DelayLink
                                        delay={pageTransitionDuration * 1000}
                                        toTrigger={triggerClick}
                                        to={path}
                                        className={`navigation__link ${
                                            location.pathname === path ? "active" : ""
                                        }`}
                                    >
                                        {name}
                                    </DelayLink>
                                </motion.div>
                            ))}
                            {blogRoutes.map(({ name, path, title }, index) => (
                                <motion.div
                                    key={index}
                                    variants={menuItemVariants}
                                    className={`navigation__item navigation__item--blog`}
                                >
                                    <DelayLink
                                        delay={pageTransitionDuration * 1000}
                                        toTrigger={triggerClick}
                                        to={path.replace(":lang", "en")}
                                        className={`navigation__link navigation__link--blog ${
                                            location.pathname.indexOf("/blog") > -1 ? "active" : ""
                                        }`}
                                    >
                                        {name}
                                        <span className="navigation__link-sub-title">New post</span>
                                    </DelayLink>
                                </motion.div>
                            ))}
                            {clicheTalesRoutes.map(({name, path, title}, index) => (
                                <motion.div
                                    key={index}
                                    variants={menuItemVariants}
                                    className={`navigation__item navigation__item--blog navigation__item--cliche`}
                                >
                                    <DelayLink delay={pageTransitionDuration * 1000} toTrigger={triggerClick}
                                               to={path} key={index}
                                               className={`navigation__link navigation__link--blog navigation__link--cliche ${location.pathname === path ? 'active' : ''}`}
                                    >
                                        {name}
                                        <span className="navigation__link-sub-title">{title}</span>
                                    </DelayLink>
                                </motion.div>
                            ))}
                        </motion.div>
                        <motion.div className="overlay__background"
                                    variants={overlayBackgroundVariants}
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                        >&nbsp;</motion.div>
                    </motion.aside>
                )}
            </AnimatePresence>
        </>
    )
}

export default Overlay;
