import Post1 from "./posts/post-1";
import Post2 from "./posts/post-2";
import Post3 from "./posts/post-3";
import Post4 from "./posts/post-4";
import Post5 from "./posts/post-5";
import Post6 from "./posts/post-6";
import {useLocation, useNavigate} from "react-router-dom";

const posts = [
    {
        id: "mindfckery",
        title_en: "MindF*ckery",
        title_hu: "Agypén*sz",
        description_hu: "Egy homeofficesapiens csatakiáltása egy buborékból az ürességbe.",
        description_en: "A homeofficesapien’s battle cry into the void from a bubble.",
        date: "6 Dec 2024",
        image: "/images/blog/blog-6.png",
        thumbnail_hu: "/images/blog/blog-6-og-thumbnail-hu.png",
        thumbnail_en: "/images/blog/blog-6-og-thumbnail-en.png",
        content: <Post6/>
    },
    {
        id: "whys-your-yogi-friend-crying",
        title_en: "Why's your yogi friend crying",
        title_hu: "Miért sír a jógi barátod",
        title_es: "¿Por qué está llorando tu amigo yogui?",
        description_hu: "GuruJi, Sharath Jois elvesztéséről",
        description_en: "On the lost of GuruJi, Sharath Jois",
        description_es: "Sobre la pérdida de GuruJi, Sharath Jois",
        date: "19 Nov 2024",
        image: "/images/blog/blog-5.png",
        thumbnail_hu: "/images/blog/blog-5-og-thumbnail-hu.png",
        thumbnail_en: "/images/blog/blog-5-og-thumbnail-en.png",
        thumbnail_es: "/images/blog/blog-5-og-thumbnail-es.png",
        content: <Post5/>
    },
    {
        id: "the-wolf-of-hatfield-part-1",
        title_en: "The Wolf of Hatfield",
        title_hu: "Hatfield Farkasa",
        part_en: "Part One",
        part_hu: "Első Rész",
        description_hu: "Hogyan keveredtem Angliába.",
        description_en: "How did I end up in England",
        date: "3 Apr 2024",
        image: "/images/blog/blog-4.png",
        thumbnail_hu: "/images/blog/blog-4-og-thumbnail-hu.png",
        thumbnail_en: "/images/blog/blog-4-og-thumbnail-en.png",
        content: <Post4/>
    },
    {
        id: "the-job-game",
        title_en: "The job game",
        title_hu: "A munka játszma",
        description_hu: "Néhány szösszenet álláskereső pózból.",
        description_en: "Some snippets from a job seeker stance.",
        date: "24 Feb 2024",
        image: "/images/blog/blog-3.png",
        thumbnail_hu: "/images/blog/blog-3-og-thumbnail-hu.png",
        thumbnail_en: "/images/blog/blog-3-og-thumbnail-en.png",
        content: <Post3/>
    },
    {
        id: "a-b-1-2",
        title_en: "A B 1 2",
        title_hu: "A B 1 2",
        description_hu: "Életmód kérdések, frusztrációk kivesézése (nem szó szerint).",
        description_en: "Dissecting lifestyle issues and frustrations (not literally).",
        date: "13 Feb 2024",
        image: "/images/blog/blog-2.png",
        thumbnail_hu: "/images/blog/blog-2-og-thumbnail-hu.png",
        thumbnail_en: "/images/blog/blog-2-og-thumbnail-en.png",
        content: <Post2/>
    },
    {
        id: "thats-right-man",
        title_en: "That's right, man",
        title_hu: "Az igen, ember",
        description_hu: "Ez az első bejegyzés, arról, hogy ki vagyok meg mire számíthatsz itt.",
        description_en: "This is the first post and it's about who I am and what you can expect here.",
        date: "25 Jan 2024",
        image: "/images/blog/blog-1.png",
        thumbnail_hu: "/images/blog/blog-1-og-thumbnail-hu.png",
        thumbnail_en: "/images/blog/blog-1-og-thumbnail-en.png",
        content: <Post1/>
    }
];

export function changeLanguage(navigate, location, changeTo) {
    let url = location.pathname.toString();
    url = url.replace(/^.{3}/g, changeTo);
    navigate('/' + url);
}

export function getPost(postId) {
    return posts.find(({id}) => postId === id);
}

export function getPosts() {
    return posts.map(({id, title_en, title_hu,description_hu,description_en, date, image}) => ({id, title_en, title_hu, description_hu, description_en, date, image}));
}

export function getPostById(postId) {
    const index = posts.findIndex(({ id }) => postId === id);

    if (index === -1) {
        return { post: null, prevPost: null, nextPost: null }; // Handle invalid ID gracefully
    }

    const post = posts[index];
    const prevPost = index > 0 ? posts[index - 1] : null; // Previous post exists only if index > 0
    const nextPost = index < posts.length - 1 ? posts[index + 1] : null; // Next post exists only if index < last index

    return { post, prevPost, nextPost };
}

