import React, {useRef, useEffect, useState} from "react";
import {motion} from "framer-motion";
import {isMobile} from 'react-device-detect';
import {useParams, useNavigate, useLocation, Link} from "react-router-dom";
import { getPostById, changeLanguage } from "./blog/api";
import { Helmet } from 'react-helmet-async';
import {pageTransitionVariants} from "../components/PageTransition";
import SubscribeForm from "../components/SubscribeForm";

const Post = () => {
    const ref = useRef(null);
    const { id, lang } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const { post, prevPost, nextPost } = getPostById(id);

    const title =
        lang === "hu" && post.title_hu
            ? post.title_hu
            : lang === "es" && post.title_es
                ? post.title_es
                : post.title_en; // Default to English

    const description =
        lang === "hu" && post.description_hu
            ? post.description_hu
            : lang === "es" && post.description_es
                ? post.description_es
                : post.description_en; // Default to English

    const thumbnail =
        lang === "hu" && post.thumbnail_hu
            ? post.thumbnail_hu
            : lang === "es" && post.thumbnail_es
                ? post.thumbnail_es
                : post.thumbnail_en; // Default to English

    const locale =
        lang === "hu"
            ? "hu_HU"
            : lang === "es"
                ? "es_ES"
                : "en_US"; // Default to English

    const fallbackImage = post.image; // Fallback to collection view image
    const ogImage = thumbnail || fallbackImage; // Use thumbnail or fallback to collectionImage

    return (
        <>
            <Helmet>
                {/* General meta tags */}
                <meta name="viewport" content="initial-scale=1.0, width=device-width"/>
                <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
                <meta charSet="utf-8"/>
                <meta name="title" content={title}/>
                <meta name="description" content={description}/>

                {/* Open Graph meta tags */}
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={description}/>
                <meta property="og:image" content={ogImage}/>
                <meta property="og:locale" content={lang === "hu" ? "hu_HU" : "en_US"}/>
                <meta property="og:url" content={`${window.location.origin}${location.pathname}`}/>
                <meta property="og:site_name" content="Zolly's blog"/>

                {/* Twitter Card meta tags (optional, but recommended) */}
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={title}/>
                <meta name="twitter:url" content={`${window.location.origin}${location.pathname}`} />
                <meta name="twitter:description" content={description}/>
                <meta name="twitter:image" content={ogImage}/>
                <meta name="twitter:site" content="@ZoltanBank" />
                <meta name="twitter:creator" content="@ZoltanBank" />

                <title>{title}</title>
            </Helmet>

            <motion.div
                className="page"
                variants={pageTransitionVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <div className="site-frame site-frame--top">
                    <a className={`${lang === "en" ? 'active' : ''} blog__language-button`} onClick={() => {
                        changeLanguage(navigate, location, 'en')
                    }}>
                        {isMobile
                            ? <>EN</>
                            : <>English</>
                        }
                    </a>
                    <a className={`${lang === "hu" ? 'active' : ''} blog__language-button`} onClick={() => {
                        changeLanguage(navigate, location, 'hu')
                    }}>
                        {isMobile
                            ? <>HU</>
                            : <>Magyar</>
                        }
                    </a>
                    {post.title_es ?
                        <a className={`${lang === "es" ? 'active' : ''} blog__language-button`} onClick={() => {
                            changeLanguage(navigate, location, 'es')
                        }}>
                            {isMobile
                                ? <>ES</>
                                : <>Español</>
                            }
                        </a>
                        : <></>
                    }
                </div>
                <section className="section hero no-padding">
                    <div className="hero__content padding-sm--left padding-sm--right">
                        <div className="hero__title-group">
                            {lang === "es" ?
                                <Link className="link margin-lg--bottom" to={`/en/blog`}>
                                    Volver a todas las publicaciones
                                </Link>
                                :
                                <Link className="link margin-lg--bottom" to={`/${lang}/blog`}>
                                    <>
                                        {lang === "hu"
                                            ? <>Vissza a bejegyzésekhez</>
                                            : <>Back to all posts</>
                                        }
                                    </>
                                </Link>
                            }
                            {lang === "es" ?
                                <h1 className="hero__title hero__title--es heading heading--1 align--center margin-xl--bottom">
                                    {post.title_es}
                                </h1>
                                :
                                <h1 className="hero__title heading heading--1 align--center margin-xl--bottom">
                                    {lang === "hu"
                                        ? <>{post.title_hu}</>
                                        : <>{post.title_en}</>
                                    }
                                    {post.part_hu && lang === "hu" &&
                                        <span className="hero__part heading heading--4">{post.part_hu}</span>
                                    }
                                    {post.part_en && lang === "en" &&
                                        <span className="hero__part heading heading--4">{post.part_en}</span>
                                    }
                                </h1>
                            }
                            {lang === "es" ?
                                <p className="margin-sm-xl--bottom">
                                    Deslicemos y pasemos.
                                </p>
                                :
                                <p className="margin-sm-xl--bottom">
                                    {lang === "hu"
                                        ? <>Menjen a scroll meg a swipe</>
                                        : <>Let's scroll and swipe</>
                                    }
                                </p>
                            }
                        </div>
                        <div className="hero__arrow">
                            <div className="hero__arrow-box">&nbsp;</div>
                        </div>
                    </div>
                </section>
                <div className="blog" ref={ref}>
                    <div className="container container--800 blog__content">
                        {post.content}
                    </div>
                </div>
                <div id="subscribe" className="blog__subscribe no-margin">
                    <div className="container container--700 blog__content">
                        <div className="container container--small">
                            <SubscribeForm lang={lang}/>
                        </div>
                    </div>
                </div>
                <div className="container align--center">
                    <div className="post-navigation">
                        {nextPost && (
                            <Link to={`/${lang}/blog/${nextPost.id}`} className="post-navigation__link post-navigation__link--prev">
                                {lang === "hu" ? nextPost.title_hu : nextPost.title_en}
                            </Link>
                        )}
                        <Link to={`/${lang}/blog`} className="post-navigation__link">
                            {lang === "hu"
                                ? <>Vissza a bejegyzésekhez</>
                                : lang === "es"
                                    ? <>Volver a todas las publicaciones</>
                                    : <>Back to all posts</>}
                        </Link>
                        {prevPost && (
                            <Link to={`/${lang}/blog/${prevPost.id}`} className="post-navigation__link post-navigation__link--next">
                                {lang === "hu" ? prevPost.title_hu : prevPost.title_en}
                            </Link>
                        )}
                    </div>
                </div>

            </motion.div>
        </>
    );
}

export default Post;
