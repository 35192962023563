import "./App.scss";
import React, {useState, useEffect} from "react";
import {isMobile} from 'react-device-detect';
import {AnimatePresence} from "framer-motion";
import {useLocation, matchPath, useRoutes} from "react-router-dom";
import {routesConfig} from "./config/routes";
import SiteFrame from "./components/SiteFrame";
import Cursor from "./components/Cursor";
import {pageTransitionDelay} from "./transitions/TransitionConstants";
import PageTransition from './components/PageTransition';
import {HelmetProvider} from 'react-helmet-async';

function App() {
    const [overlayVisible, setShowOverlay] = useState(false);
    const element = useRoutes(routesConfig);
    const location = useLocation();
    const currentRoute = routesConfig.find(
        route => matchPath(route, location.pathname)
    )
    const htmlRoot = document.getElementsByTagName("html")[0];
    const currentPage = location.pathname.replace(/\//g, '') || 'projects';

    const isBlogPage = currentRoute?.group === 'blog';
    const blogClass = isBlogPage ? 'blog' : '';
    const currentPageClass = `content ${blogClass} page-${isBlogPage ? 'es' : ''}`;

    // Set page height to 100% to avoid mobile 100vh issue
    const appHeight = () => {
        htmlRoot.style.setProperty('--app-height', `${window.innerHeight}px`);
    }
    window.addEventListener('resize', appHeight);
    appHeight();

    // Add page name to HTML tag on first load
    if (!htmlRoot.getAttribute("page")) {
        htmlRoot.setAttribute("page", currentPage);
    }

    useEffect(() => {
        // Add page name to HTML tag
        setTimeout(function () {
            htmlRoot.setAttribute("page", currentRoute.class);
        }, pageTransitionDelay * 1000);

        // Scroll top page on transition buy delay it by the start delay
        if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
            window.setTimeout(() => window.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant"
            }), pageTransitionDelay * 1000);
        }
    }, [location]);

    useEffect(() => {
        htmlRoot.setAttribute('overlay-visible', overlayVisible);
    }, [overlayVisible]);

    if (!element) return null;

    return (
        <>
            {!process.env.NODE_ENV || process.env.NODE_ENV !== 'development' &&
                <>
                    {!isMobile &&
                        <Cursor/>
                    }
                </>
            }
            <div className={currentPageClass.trim()}>
                <AnimatePresence mode="wait">
                    <PageTransition key={currentPage}>
                        <HelmetProvider>
                            {React.cloneElement(element, {
                                key: location.pathname,
                                page: currentPage,
                                overlayVisible: {overlayVisible},
                                setShowOverlay: {setShowOverlay}
                            })}
                        </HelmetProvider>
                    </PageTransition>
                </AnimatePresence>
                <SiteFrame currentPage={currentPage} overlayVisible={overlayVisible} setShowOverlay={setShowOverlay}/>
            </div>
        </>
    );
}

export default App;
