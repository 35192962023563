import React from "react";
import { useParams } from "react-router-dom";
import Spotify from "../blocks/spotify";

const Post6 = () => {
    const { id, lang } = useParams();

    return (
        <>
            {lang === "hu"
                ?
                <>
                    <article className="blog__article">
                        <p>Jobban áll nekem ha viccelődöm mint amikor komoly vagyok. Az igazság az, hogy jobb érzés is,
                            viszont néha elkap a merengés, mint Micimackót. Annyian állnak feltett kézzel körülöttem
                            néha, nem tudva merre tovább, hogy ez áttapad rám is. Nem mintha én tudnám, csak ez talán
                            nem aggaszt annyira. Ilyen alaphelyzetes vagyok. Mondjuk mégis aggaszt kicsit? Hm… lehet rám
                            aggatták az aggódók az aggódást, hogy aggasszon.</p>
                        <blockquote>Az aggodalom nem veszi el a holnap gondjait, de elveszi a mai nap békéjét.<span>Randy Armstrong</span>
                        </blockquote>
                        <p>Egyre többet foglalkozom azzal, hogy megértsem az érzelmeimet. Nem igazán szokása ez a
                            generációmnak. Na jó, lehet ez így kicsit nagyzó, mert, amúgy szart se tudok a
                            generációkról, nem kutakodtam a témában, szóval átfogalmazom. A korosztályomba tartozó
                            emberek, akiket ismerek, nagyon kis százaléka foglalkozik saját maga megértésével, mentális
                            egészségével. Volt egy pozitív trend a vakcinás idők előtt/közben, ha emlékszel még a
                            wellbeing (jól lét) szó széthasználására, de az csak úgy mint a hatóanyag, elmúlt, és nem
                            kértünk ismétlést.</p>
                        <Spotify song={"6HxweNytuEQdmGeVtZPZz3"}/>
                        <p>Kicsit visszább csévélem a VHS-t (87-es vagyok, ami a korosztálykérdésben segíthet). Az
                            ezredforduló környékén nevelkedtem, egy országban ahol még csak próbaüzem volt a demokrácia.
                            Jelenleg már egy ilyen Temus demokrácia fut, ez a legjobb amit a kárpát medence adni tud.
                            Egy mindent átfogó változás, a végtelen lehetőségek gyógyító/mérgező évei voltak ezek.
                            Megjelent a zinternet, és mint a kíváncsi gyerekek, mind szétnyomkodtuk. Nem volt kontroll,
                            szabadság volt, ami nem volt a legigazibb, mert nem volt kontroll, ami irónikusan a
                            szabadság hozzávalója. Még mindig nem tiszta az okosoknak teljesen, hogy mennyit is ártott
                            és használt ez az időszak, de sok szar dolgot köszönhetünk neki a jók mellett, az is
                            biztos.</p>
                        <p>Suli után ilyen Steve Jobs-nak láttam magam, a web fejlődése beszopott, mindent tudni
                            akartam, és minden nap úgy keltem, hogy valami sorsfordító új dolgot fogok csinálni. Nem
                            mondom, hogy nem sikerült, bár olyan értelemben nem, hogy világformálóvá válljak, de én
                            akkor úgy éltem meg az alkotás folyamatát, hogy az maga a kiteljesedés. Annyi volt a baj,
                            hogy szar volt a pénz.</p>
                        <p>Kocogtatta a tudatom egy gondolat, hogy több kell legyen ebben. Nem lehet az élet ilyen
                            számlától-számláig pattogás azt kész. A legtöbb ismerősöm folyton “bulizott”, vagyis kevésbé
                            kiszinezve, ivott, drogozott, nyomott magába amit tudott, hogy letompítsa az élet élét,
                            elkerülje a felnőttködést. Én is csatlakoztam néha, de leginkább nonstop dolgoztam, én abba
                            menekültem a válaszokért. Az volt a baj, hogy az ivás se volt a régi mert haragudtam tőle
                            mindenre, főképp magamra és mindenre meg magamra (a mindent mondtam már?).</p>
                        <p>Ahogy közeledtem a harmincas klubhoz, a belső hangom egyre fülsüketítőbben nyomatta, hogy
                            kezdjek el figyelni. Kb résnyire kinyilt a szemem, de nehezen ment több. Volt azért bennem
                            ellenállás hajjaj. Már a mentális higiénia említése is néha olyan érzést keltett bennem
                            mintha valaki épp keverné a Tarot kártyát, hogy megmondja nekem mi lesz.</p>
                        <blockquote>Megállítani, ez az ellenállással van<span>Rohamosztagos #87330</span></blockquote>
                        <p>Az a három X az viszont pofán csapott, mint Will Smith Chris Rockot. Olyan lassított
                            felvételes, takony meg nyál kicsordulósan. Bármilyen közhelyesnek is láttam egy helyben
                            járás érzés fogott el (közhelyben járás, trademark). Volt egy piac képes tudásom amit a piac
                            a hazámban nem akart megfizetni. Ez volt az oka, hogy, mint sokan, én is rátapadtam a
                            tiplizésre (nem a reggeli felesre). Mindig azt mondtam, hogy a büdös életbe nem megyek
                            Londonba mint valami ötlettelen másológép. Khm. Mind the gap between the lame and the
                            asshole.</p>
                        <Spotify song={"124Y9LPRCAz3q2OP0iCvcJ"}/>
                        <p>Én csak úgy vagyok a kis buborékomban, se ajtó se semmi. Bezártam ide magam. Nem tudom
                            nézted-e a Black Mirror sorozatot (ajánlom), volt egy rész ami egy olyan disztópia (utópia?)
                            idején játszódott, ahol mindenkinek volt egy agyi implantátuma arra, hogy felvegye a napját,
                            infókat vetítsen a látótérbe stb. Ilyen scifi szar. Az egyik para része az volt ennek a
                            tech-nek, hogy letudtak az emberek másokat tiltani, és onnantól a másik nem hallatszott, nem
                            látszódott, egy homályos szellemmé változott (szó szerinti ghostolás). Valahol, ugyanez
                            történik most mindenkivel, aki beszopódik a közösségi médiában, csak kevésbé van tudatában,
                            hogy éppen kik ghostolják.</p>
                        <p>Az irányítás nem a mienk, vagyis nagyon kis százalékban a mi döntésünk, hogy melyik buborékba
                            kerülünk, hogy kinek válunk láthatatlanná. A buborékban a víszhang viszont elhiteti, hogy az
                            igazság velünk van, pedig valójában ebben a digitális kupiban csak töredék dollár centek
                            vagyunk, amit algoritmusok folyamatosan olyanra formálnak, hogy naponta, visszatérően,
                            hűségesen megjelenjünk. Szerencsére most, hogy a gép már értelmes, így teljesen deformálni
                            képes a valóságot. Például mikor megkérdeztem mit gondol a chat-gábor-péter-tamás az
                            írásomról, azt mondta nagyon szellemes, elgondolkodtató, mély és mégis könnyed. Nemá’ éjjájj
                            elpirulok.</p>
                        <p>Ha a valóság sem biztos, kurvára nem csoda, hogy azt se tudjuk kinek kéne lennünk, végképp,
                            ha a buborékunk feladata, hogy ezt érezzük, hogy távol tartson minket az ábrándozástól,
                            felfedezéstől. Feltartott kezű emojivá válunk lassan mind.</p>
                        <p>És itt érkezek el az eredeti témához amit érinteni akartam ebben a bejegyzésben, a férfi- és
                            női szerepek változásához. Én is érzem, hogy nem valami stabil síneken halad ez az írás, de
                            többször átírtam és valahogy mégis ez így tűnt olyannak, ami tükrözi a fejemben lévő
                            agypén*szt.</p>
                        <p>Szóval, férfi és nő. Alapból már ez a bináris felfogás nem elfogadható egyeseknek (nem úgy
                            egyes, hogy bináris 1, no offense), amiről nekem igazából véleményem sincs, legyen ahogy
                            akarja akárki (jelenleg a két nemes összefügés amin agyalok, ennyi). A boldogság és a
                            szeretet mindenkinek jár. Az is mindenkinek jár, hogy bármit érezzen bármiről, és bármit
                            gondoljon. Akár mások nevében is meg lehet sértődni, bár azt amúgy leleplezem, hogy az nem
                            ugyanaz mint az empátia.</p>
                        <Spotify song={"7iN1s7xHE4ifF5povM6A48"}/>
                        <p>Volt valami videó amit láttam, és azt magyarázta benne egy faszi, hogy tíz másodperc alatt
                            megcáfolja a nemek közti fizetési különbségek vitáját. Nem akarlak felzaklatni de szerintem
                            igaza volt. Tudom mit gondolsz most rólam, főképp női olvasóként, de az érvelése téged is
                            meg fog győzni szerintem. Azt mondta, hogy:</p>
                        <blockquote>...persze, hogy a férfiak többet keresnek hiszen ők, ügyvédek, tanárok, menedzserek
                            stb. Ezzel szemben a nők csak olyan munkákat vállalnak mint ügyvéd nő, tanárnő, női
                            menedzser stb.<span>Very bölcs dude</span></blockquote>
                        <p>Ha eddig megtartottalak és nem jött át, apu szarkazmust kedvelő libernyák hányása vagyok.</p>
                        <p>Azt akarta tőlünk a társadalmunk, hogy szabaduljunk fel, valósítsuk meg magunkat nemtől
                            függetlenül. Csak nyomjuk. Aztán eltelt harminc év, és a demográfiai mutatók elromlottak.
                            Ezt követte egy üzenet váltás, hogy oké, hogy nyomod, de azért tudd hol a helyed, a
                            szaporodás az a legnőiesebb dolog a világon (néha még azt is mondják a legférfiasabb), meg
                            amúgy is a biológiai óránk az nem várja meg az előléptetést stb.</p>
                        <p>Igazából amit a kapitalizmus amiben élünk elvár az kb minden. Ahhoz, hogy elégedett
                            fogyasztók legyünk dolgoznunk kell, különben se lakni se enni nem tudunk nem, hogy szórni a
                            pénzt. Viszont a régi világ értekeit is kövessük, tehát csináljunk gyerekeket, mert ebben a
                            rendszerben nem csak a cégeknek kell folyamatosan növelni a profitot, javítani a mutatókat,
                            hanem az államnak is. Szóval csinálj egy jó karriert, ami eltart, amire építhetsz, miközben
                            összehozol egy párkapcsolatot amivel tudsz családot alapítani, ami a jövőt építi. Ha meg
                            esetleg ez harminc felé nem sikerült, akkor hallgasd meg a kórust, hogy mekkora szar vagy,
                            hogy az élet értlemét megtagadtad. Esetleg fizess extra adókat is mert hazaáruló,
                            szaporodástalan vagy.</p>
                        <p>A nőkön a legnagyobb a nyomás, nekik úgy kell dolgozniuk, hogy az ugye csak női munka. Szóval
                            igazán alap, hogy a háztartást is vezessék, meg az is, hogy nem kell nekik annyi pénzt adni
                            (szarkazmus, emlékszel?). A férfiak közül sokan akiket ismerek, meg nem igazán szeretnek
                            gondolkodni a tetteiken, és az érzéseiket elemezni, szóval van akit elkap ez a régi
                            értékrendekbe kapaszkodás, hogy hol a nő helye stb, leszarva melyik világban élünk most.
                            Engem meg kurvára zavar az igazságtalanság. Az is megfigyelhető az ilyen fasziknál, hogy
                            csak akkor szajkózzák az 1960-as értékeket amikor kényelmes, nem vezet semmilyen
                            lemondáshoz.</p>
                        <p>Nem mindenkinek könnyű összehozni egy gyereket, sőt, a tapasztalatom az, hogy sokaknak nagyon
                            nehéz. Ez könnyen megoszt egy társaságot és ingoványos talaj a közösségi médiában is. Igen
                            alap, hogy a munkahelyen a kutyás-cicás és a gyerekes csopi külön társalog. Ha megfigyeled
                            ez a két legerősebb gravitációs tér ilyen környezetben. Ha meg vegán vagy akkor egyedül vagy
                            akkor is ha gyereked van. Csak ülsz a sarokba azt csámcsogsz egy répán.</p>
                        <p>Ahogy én látom két féle ember van (és a többi másféle):</p>
                        <ol className="list display--block">
                            <li>A már a szülővévállás első másodpercében ős-szülövé vált emberek</li>
                            <li>És akiknek nincs gyerekük tehát fossá unják már a gyerek fotókat emberek</li>
                        </ol>
                        <p>Persze ez csak vicc. Ha nem vagy szerencsés, szar tud lenni mikor mások az orrod alá dörgölik, esetleg kiokítanak, hogy az élet célod hibás, és persze nem is akarsz leiratkozni a haverjaidról, bár néha nincs más út. Ha meg sikerült az is érthető, hogy mindent az utolsó kakkantásig meg akar az ember osztani másokkal, mert hát, egészen egyértelmű, hogy a világ történelem legesleg csodálatosabb kakija keszült épp, ami természtesen így is van. A lényeg amit átakarok adni, hogy tapintatosnak kell lenni másokkal, mind egyenrangúak vagyunk itt az istenek játszóterén.</p>
                        <p>Amikor nem jön össze hiába ad bele mindent az ember a családkészítésbe (ami kell is a sikerhez), és ennek ellenére vannak akik megbélyegzik őket, önző, karrierista és hasonló jelzőkkel, azokat én csak egy kobold-fingnak találom. Mindent magunkból indítunk, de ha esetleg úgy jön ki egy gondolat vagy vélemény belőled, hogy a felvezetés után van egy “csak”, “de”, akkor javaslom ne adj ki hangokat inkább. Egy példa mondat aminél inkább csend pl., hogy “fontos megtalálni amit szeretsz csinálni, csak hát nem leszel fiatalabb”.</p>
                        <p>Nem állítom, hogy tudom mi a megoldás, mert igen nehéz mindenki érzékeny pontját felmérni mielőtt bele kezd az ember egy témába, de azért érdemes megfontolni, figyelni a másik jelzéseit (a non-verbálisakat is) és leszállni a gázról mielőtt lélekbe gázolsz. Nem tudhatod kinek milyen nehéz az ami neked egyszerűbb. Ja, az élőzésre értem ezt, online simán le lehet iratkozni. Persze ez csak azoknak szólt akiket érdekel mások érzelmi világa, ha esetleg nárcisztikus, ne adj isten pszichopata arc vagy, akkor meg csak előre ahogy eddig, nyomasd amíg le nem ég a világ körülötted.</p>
                        <p>Nem tudom mi a magyar szó arra, hogy bully (mondjuk zaklató, bántalmazó) de ne válljunk véletlen ilyenné. A legegyszerűbb recept ehhez, ami nekem beválik, hogy bent kell tartani a véleményt, ha nem kérdezik. Ha pedig kérdezik akkor is úgy kell megfogalmazni, hogy az ne legyen bántó (semmi “csak”). Ha ezt olvasva elképzelted magad ilyen empatikusnak és esetleg gyengének láttad magad, és azt gondolod neked igenis jogod van kimondani amit akarsz, akkor javaslom kezdj el gyakorolni mert már lehet baj van ott bent. Attól, hogy valami jogos, attól még nem lesz morálisan helyes. Rengeteg dolog volt a történelemben már jogos ami ilyen sötét jedi cucc volt.</p>
                        <p>Nem vagyok senkise amúgy, főleg nem tökéletes. Én is dolgozom a tudatossá váláson. Elég nagy kihívás ez sokszor, de gyakorlással egyre könnyebb. Amire buzdítani szeretnélek, hogy legyél újra gyerek, és kezdd el újra megkérdőjelezni a világ dolgait. Nyomd fullba a “miért” gombot az segíteni fog elindulni az önismeret útján. Azért csak kipörgettem ezt valamire a végén, fuh.</p>
                        <p>Köszi, hogy olvasol. <strong>Shanti.</strong></p>
                    </article>
                </>
                :
                <></>
            }
            {lang === "en"
                ?
                <>
                    <article className="blog__article">
                        <p>It suits me better when I’m joking around than when I’m being serious. Truth is, it feels better too, but sometimes I get lost in thought, like Winnie the Pooh. There are many people around me with their hands in the air, not knowing which way to go, and it kind of rubs off on me. Not that I know either, it just doesn’t seem to bother me as much. That’s just my default setting. Or... maybe it does bother me a little? Hm... I guess the worriers have hung their worries on me to make me worry.</p>
                        <blockquote>Worrying does not take away tomorrow’s troubles. It takes away today’s peace.<span>Randy Armstrong</span>
                        </blockquote>
                        <p>I’ve been spending more and more time trying to understand my emotions. Not exactly something my generation is known for. Okay, maybe that’s a bit of an exaggeration because honestly, I don’t know crap about generations. I haven’t exactly done my homework on the topic. So, let me rephrase: only a tiny percentage of the people in my age group that I know actually spend time trying to understand themselves or care about their mental health. There was a positive trend for it back in the vaccine days if you still remember the overuse of the word wellbeing. But like the vaccines, it wore off, and nobody really cared about getting a booster.</p>
                        <Spotify song={"6HxweNytuEQdmGeVtZPZz3"}/>
                        <p>Let me rewind the VHS a bit (I’m an ‘87 baby, that should help with the generation thing). I grew up around the turn of the millennium, in a country where democracy was still basically in beta testing. Now we’re running on this Temu version of democracy, the best the Carpathian Basin could bear. Those were years of sweeping change, the era of endless possibilities, healing/poisoning us all. The internet showed up, and like curious kids, we poked every button. No control, just freedom, though not the “realest” kind because real freedom needs some control, ironically enough. The brainiacs still don’t fully get how much that era helped or hurt, but yeah, it gave us plenty of trash along with the good stuff, that’s for sure.</p>
                        <p>After school, I saw myself as some kind of Steve Jobs. I got sucked into the evolution of the web, wanted to know everything, and every morning I woke up convinced I’d create something groundbreaking. And maybe I did, though not in the sense of reshaping the world. But for me, the process of creating felt like the ultimate fulfilment. The problem was, the money sucked.</p>
                        <p>There was this thought knocking around in my head: there has to be more to this. Life can’t just be bouncing from one bill to the next, and that’s it. Most of the people I knew were always “partying,” which, to put it less nicely, meant drinking, doing drugs, and shoving anything into themselves to dull the sharp edges of life and avoid growing up. I joined them occasionally, but mostly, I worked nonstop, throwing myself into it to find answers. The problem was, that drinking wasn’t what it used to be. I just ended up angry at everything, mostly at myself. And everything. And myself. Did I mention everything?</p>
                        <p>As I was closing in on the thirties club, my inner voice started screaming louder and louder, telling me to pay attention. I cracked my eyes open a bit but that's all. Boy I resisted hard. Just hearing the words “mental hygiene” made me feel like someone was shuffling a Tarot deck to tell me my destiny.</p>
                        <blockquote>Stop him, he’s with the resistance<span>Stormtrooper #87330</span></blockquote>
                        <p>Then those three Xs smacked me in the face like Will Smith did Chris Rock. Like in slow motion, with snot and spit flying everywhere. Call it a cliché, but I felt this “stuck in place” feeling (like I was alone). I had a marketable skillset, but my home country’s market wasn’t exactly willing to pay for it. That’s what pushed me, like so many others, to start eyeing the exit. I always swore I’d never move to London, refusing to be a brainless copier. Ahem. Mind the gap between the lame and the asshole.</p>
                        <Spotify song={"124Y9LPRCAz3q2OP0iCvcJ"}/>
                        <p>So here I am, in my little bubble, no door or anything. Just shut myself in. Not sure if you’ve watched Black Mirror (highly recommend), but there’s an episode set in this dystopian (utopian?) future where everyone has these brain implants that record your day, project info into your field of vision, and all that sci-fi stuff. The creepy part of this tech was that people could block each other, and once someone was blocked, they couldn’t be seen or heard anymore. Just turned into this blurry ghost (literal ghosting). In a way, the same thing is happening now to everyone sucked into social media. The only difference is, most people don’t realize who’s ghosting them.</p>
                        <p>We barely control which bubble we land in or who we go invisible to. But the echo in the bubble tricks us into thinking we’ve got “the truth.” When really, in this digital mess, we’re just fractions of a dollar, shaped by algorithms so we keep coming back every day. And now that AI’s got a brain, it can totally twist reality. For example, when I asked chat-george-peter-tom what it thought of my writing, it said it was “witty, thought-provoking, deep, and lighthearted.” Aww, stop it I am blushing.</p>
                        <p>If reality’s uncertain, it’s no wonder we have no fucking clue who we’re supposed to be. Especially when our bubble’s job is to make sure we feel that way, to keep us from dreaming or exploring. We’re all turning into raised-hand emojis sooner or later.</p>
                        <p>And that brings me to the main point I wanted to touch on in this post – the men’s and women’s roles. I know this writing isn’t exactly on a steady track, but I rewrote it a few times, and this version feels closest to the mindf*ckery in my head.</p>
                        <p>So, men and women. Right off, this binary thing isn’t acceptable to ones (no offence not one like binary 1), and honestly, I don’t even have an opinion on that. People can do as they please. (At the moment, my focus is just on the dynamic between the two genders we all know.) Everyone deserves happiness and love. People should feel however they want about anything. You can even be offended on behalf of others, though I’d like to point out that’s not the same thing as empathy. Just saying.</p>
                        <Spotify song={"7iN1s7xHE4ifF5povM6A48"}/>
                        <p>I saw a video where a guy claimed he could debunk the gender pay gap debate in ten seconds. I don’t mean to upset you, but I think he had a point. I know what you’re probably thinking about me right now, especially if you’re a woman reading this, but hear me out, his logic might convince you too. He said:</p>
                        <blockquote>…of course, men earn more because they’re lawyers, teachers, managers, etc. Meanwhile, women only take on jobs like female lawyer, female teacher, female manager, etc.<span>Very wise dude</span></blockquote>
                        <p>If you’re still reading and didn’t get it - Hi, I’m Dad’s sarcasm-loving, liberal vomit.</p>
                        <p>Our society once wanted us to break free and realize our potential regardless of gender. Just go for it. Then thirty years passed, and the demographic numbers took a hit. That was followed by a shift in messaging: “Sure, go for it, but don’t forget your place - procreation is the most womanly thing in the world” (sometimes they even say it’s the most masculine). Oh, and by the way, your biological clock won’t wait for that promotion, so... no pressure.</p>
                        <p>The truth is capitalism, the system we live in, expects pretty much everything from us. To be satisfied consumers, we have to work. Otherwise, we can’t afford a place to live or food to eat, let alone spend money on anything else. But at the same time, we’re supposed to uphold the values of the old world, meaning: make kids. Because in this system, it’s not just companies that need to constantly grow profits and improve metrics - states do too. So, build a solid career that supports you and gives you a foundation, while also starting a family to secure the future. And if you happen to reach thirty without having managed both, get ready to hear the choir about how worthless you are for rejecting the “meaning of life.” Maybe even pay some extra taxes for being a traitor to reproduction and your homeland.</p>
                        <p>The pressure is hardest on women, they’re expected to work as if they don’t work at all, so it’s only logical (sarcasm, remember?) that they should also run the household. Oh, and naturally, they don’t need to be paid as much. Meanwhile, a lot of the men I know don’t particularly enjoy reflecting on their actions or analyzing their feelings. Some of them cling to old-fashioned values, completely ignoring the world we actually live in now. I find injustice incredibly fucking frustrating. And here’s the thing about these guys: they only preach 1960s values when it’s convenient, never when it requires any actual sacrifice.</p>
                        <p>It’s not easy for everyone to have a child. In fact, from my experience, it’s really hard for a lot of people. This can easily create divisions within a group and becomes tricky ground on social media as well. It’s pretty standard for workplaces to have separate groups for pet lovers and parents. If you pay attention, these are the two strongest gravitational forces in such environments. And if you’re vegan, well, you’re on your own even if you have kids. You’re just sitting in the corner, munching on a carrot.</p>
                        <p>As I see it, there are two types of people (plus, of course, all the other types):</p>
                        <ol className="list display--block">
                            <li>Those who become the unquestionable ultimate parent the second they have a child.</li>
                            <li>2. And those who don’t have kids and are absolutely sick of seeing baby photos.</li>
                        </ol>
                        <p>Obviously, this is just a joke. If you’re not fortunate in this area, it can feel awful when others rub it in your face or lecture you about how your life is without a purpose. At the same time, you don’t necessarily want to unfollow your friends, even though sometimes that’s the only option. On the other hand, if you do succeed in having kids, it’s totally understandable that you want to share every little moment, even the latest poop, because, let’s face it, it’s clearly the most amazing poop in the history of poops. And honestly, it probably is. The main point I’m trying to make here is that we all need to be mindful with one another. We’re all equals here on the playground of Gods.</p>
                        <p>When someone gives everything they have to start a family (which is necessary for success) but still doesn’t succeed, and on top of that, they’re labeled as selfish, career-driven, or something similar, I can only describe those critics as nothing more than a goblin’s fart. Everything we say comes from within us, but if a thought or opinion leaves your mouth with a “but” or “just” after the initial setup, I suggest keeping quiet instead. For example, here’s a sentence that’s better left unsaid: “It’s important to find what you love doing, but you’re not getting any younger.” Just shush.</p>
                        <p>I’m not claiming to know the solution because it’s really difficult to gauge everyone’s sensitive points before diving into a topic. However, it’s worth considering, paying attention to the other person’s signals (including non-verbal ones), and easing off before you end up trampling on someone’s feelings. You never know what might be incredibly hard for someone else, even if it seems simple to you. Yeah, I’m referring to the 3D world, online it’s easy to just unfollow. Of course, this is only for those who care about other people’s emotions. If you happen to be a narcissist or, heaven forbid, a psychopath, then by all means, carry on as you have been, pushing forward until the world burns down around you.</p>
                        <p>Don’t be a bully, just don’t. The simplest approach that works for me is to keep your opinion to yourself if no one asked for it. And if someone does ask, make sure to express it in a way that isn’t hurtful (no “but” or “just”). If reading this makes you imagine yourself as empathetic but that feels weak, and you think, “I have the right to say whatever I want,” then I’d suggest working on that because something might be already off in there. Just because something is justified doesn’t mean it’s morally right. Plenty of things in history were technically “justified” but were straight-up dark Jedi-level stuff.</p>
                        <p>I’m really just a nobody, far from perfect. I’m working on becoming more self-aware too. It’s a big challenge sometimes, but with practice, it gets easier. What I’d encourage you to do is to become a kid again and start questioning the world around you. Hit the “why” button full throttle, it’ll help you start down the path of self-discovery. Well, I managed to spin this into something in the end. Phew.</p>
                        <p>Thanks for reading. <strong>Shanti.</strong></p>
                    </article>
                </>
                :
                <></>
            }
            {lang === "es"
                ?
                <>
                    <article className="blog__article">
                        <p>Paramaguru Shri R. Sharath Jois falleció de un ataque al corazón durante una caminata en
                            Virginia. El solo hecho de decirlo me enferma. Personas mucho más sabias y experimentadas
                            que yo ya han escrito sus despedidas a GuruJi, personas que lo conocieron durante décadas y
                            que no eran solo estudiantes, sino amigos. Yo ni siquiera intentaría igualar esos homenajes:
                            Wade, Kino, Hamish y muchos otros han expresado maravillosamente con palabras lo que
                            significa la partida de SharathJi.</p>
                        <p>Este escrito no es para yoguis. Ellos conocen estos nombres, han visto las publicaciones y no
                            han hecho mucho más desde que escucharon la noticia. Quiero ayudar a quienes no entienden la
                            magnitud de esta pérdida, a quienes no lo conocieron. Personas como mis padres, tal vez. Y
                            espero que también ayude a quienes se quedaron en silencio al escuchar la noticia, incapaces
                            de expresar su dolor con palabras. O a quienes no pueden evitar sonreír ante la palabra
                            "gurú". No lo digo como un insulto: yo solía ser uno de ellos. Al menos hasta que fui a
                            India.</p>
                        <p className="spotify box no-padding--top">
                            <iframe
                                src="https://open.spotify.com/embed/track/7FwBtcecmlpc1sLySPXeGE?utm_source=generator&theme=0"
                                width="100%" height="152" frameBorder="0" allowFullScreen=""
                                className="no-margin--top"
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                loading="lazy"></iframe>
                        </p>
                        <p>SharathJi pasó decenas de miles de horas practicando y enseñando yoga, continuando el trabajo
                            de toda una vida que heredó de su abuelo. Solo puedes hacer algo así si lo abordas con
                            completa pasión. SharathJi encarnaba el yoga en cada fibra de su ser. Incluso la persona más
                            escéptica (como yo) no podía evitar sentir esa energía cuando estaba cerca de él. A lo largo
                            de mis años en la escuela, nunca tuve un solo profesor que se acercara a lo que él era como
                            maestro. No levitaba del suelo ni hacía milagros; sus mensajes eran sencillos, pero llevaban
                            la fuerza de una llamarada solar.</p>
                        <blockquote>Grhh, debes relajarte. Esto es yoga, no lucha libre… esto debería ser
                            divertido.<span>SharathJi mientras luchaba conmigo</span></blockquote>
                        <p>Estas palabras fueron tan poderosas cuando las dijo que podía escucharlas claramente en mi
                            mente durante un año después. Me ayudaron a superar innumerables desafíos: solo tenía que
                            reemplazar la palabra “yoga” por cualquier cosa con la que estuviera luchando. Estar con él
                            no se trataba de perfeccionar una postura. Nunca logré perfeccionar ninguna, y él no evitaba
                            hacérmelo saber. Pero ese no era el punto. Era terapia, la más pura y efectiva.</p>
                        <p>Él no era un maestro de yoga que obtuvo su certificación en un fin de semana. Y, sin embargo,
                            no íbamos a Mysore porque no hubiera buenos maestros de yoga en otros lugares. Íbamos porque
                            él estaba allí. Él era el maestro. Un faro en medio de una tormenta de mierda, un puerto
                            seguro cuando el suelo temblaba. Una fuerza que te desaceleraba y te recordaba: eres humano.
                            No un semidiós, no un jefe, no tu dolor, no tu trabajo. Humano. Eres uno con la naturaleza,
                            uno con tu respiración.</p>
                        <p>El mundo sigue oscureciéndose, con más y más tiranos que se aprovechan de los inseguros.
                            SharathJi podía hacer desaparecer esa incertidumbre con solo una inhalación y una
                            exhalación. Te hacía una mejor persona. Y el mundo siempre necesita mejores personas; por
                            eso esta es una gran pérdida, incluso para quienes no lo conocieron. Cientos de miles, quizá
                            millones, lo están llorando en todo el mundo y en Mysore. Fue un hombre que se convirtió en
                            su propósito, alguien alrededor de quien se construyó una ciudad y que influyó en decenas de
                            miles de vidas.</p>
                        <p>Tenía solo 53 años. Aunque en su familia había antecedentes similares, no creo que la
                            genética sea la culpable (pero esa es solo mi opinión; no soy médico). Prefiero pensar que
                            es porque entregó su corazón. Tantos de nosotros queríamos un pedazo de él, y se lo dio a
                            todos. Nunca fue tacaño. Devolvió mucho a su ciudad y apoyó innumerables organizaciones
                            benéficas. Tuve la suerte de visitar un santuario de vacas con él, un lugar donde los
                            animales rescatados de mataderos encontraban un hogar. Siento que solo entendí una fracción
                            de su grandeza.</p>
                        <p>Una de las grandes preguntas ahora es: ¿qué pasa después? Esta enorme comunidad ha perdido su
                            estrella del norte, su luz guía. Andrew Bresnen, PhD, dijo solo unos días después en Austin
                            que la partida de GuruJi se siente como:</p>
                        <blockquote>Imaginen a dos hermanos: uno tiene tres años, el otro siete, y pierden a sus padres.
                            De repente, el mayor tiene que crecer de la noche a la mañana y hacerse cargo del menor.
                            Pero no está preparado; todavía es solo un niño. Yo no estoy preparado para esto…<span>Andrew Bresnen, PhD #elgrande</span>
                        </blockquote>
                        <p>Andrew es increíble, y muchos de los estudiantes de SharathJi están siguiendo sus pasos.
                            Quiero creer que todo estará bien. Aunque esto duela como el infierno, nos fortalece. Cuanto
                            más cerca estamos de la luz, más deslumbrante es y más profunda es la sombra que
                            proyectamos. O, dicho de otra manera, necesitamos la oscuridad para realmente apreciar la
                            luz.</p>
                        <p>Espero que esto ayude a quienes no entienden por qué su amigo yogui está de luto por la
                            pérdida de un hombre indio lejano. Espero que esto les ayude a ver que esta pérdida para la
                            humanidad es como cuando un genio como Einstein falleció. Pero creo que SharathJi, al dejar
                            su cuerpo físico, se ha ido al mejor lugar posible. Y para los que seguimos aquí, debemos
                            aferrarnos a la esperanza, porque la energía nunca desaparece, solo se transforma.</p>
                        <h3 className="heading heading--3">Guru Brahma<br/>Guru Vishnu<br/>Guru Devo Maheshwara</h3>
                        <p className="box">
                            If you’re up for some funny stories about my time in Mysore last year (2023) with GuruJi,
                            check out my little journal, "Cliché Tales".
                            <a href="/cliche-blog">Link to the Cliché Tales</a>
                        </p>
                    </article>
                </>
                :
                <></>
            }
        </>
    );
}

export default Post6;
