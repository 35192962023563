import React, {useRef, useEffect, useState} from "react";
import {motion} from "framer-motion";
import {isMobile} from 'react-device-detect';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {Helmet} from 'react-helmet-async';

import {getPosts, changeLanguage} from "./blog/api";
import SubscribeForm from "../components/SubscribeForm";
import {pageTransitionVariants} from "../components/PageTransition";

const Blog = () => {
    const ref = useRef(null);
    const {lang} = useParams();
    const posts = getPosts();
    const navigate = useNavigate();
    const location = useLocation();

    const scrollToClick = (e) => {
        e.preventDefault();
        const target = (e.currentTarget.getAttribute("href").substring(1));
        const targetElement = document.getElementById(target);
        if (targetElement) {
            targetElement.scrollIntoView({behavior: "smooth"});
        }
    }

    return (
        <>
            <Helmet>
                <title>Blog</title>
            </Helmet>

            <motion.div
                className="page"
                variants={pageTransitionVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <div className="site-frame site-frame--top">
                    <a className={`${lang === "en" ? 'active' : ''} blog__language-button`} onClick={() => {
                        changeLanguage(navigate, location, 'en')
                    }}>
                        {isMobile
                            ? <>EN</>
                            : <>English</>
                        }
                    </a>
                    <a className={`${lang === "hu" ? 'active' : ''} blog__language-button`} onClick={() => {
                        changeLanguage(navigate, location, 'hu')
                    }}>
                        {isMobile
                            ? <>HU</>
                            : <>Magyar</>
                        }
                    </a>
                </div>
                <div className="blog" ref={ref}>
                    <section className="section hero no-padding">
                        <div className="hero__content">
                            <div className="hero__title-group">
                                <h1 className="hero__title heading heading--1 align--center margin-xl--bottom">
                                    {lang === "hu"
                                        ? <img className="blog__logo" width="727" height="207"
                                               src="/images/blog/blog-logo-hu@2x.png"
                                               srcSet="/images/blog/blog-logo-hu.png 1x, /images/blog/blog-logo-hu@2x.png 2x"/>
                                        : <img className="blog__logo" width="727" height="207"
                                               src="/images/blog/blog-logo-en@2x.png"
                                               srcSet="/images/blog/blog-logo-en.png 1x, /images/blog/blog-logo-en@2x.png 2x"/>
                                    }
                                    {isMobile
                                        ? <>
                                            <a className="link" onClick={scrollToClick} href="#subscribe">
                                                {lang === "hu" ? <>Feliratkozás</> : <>Subscribe</>}
                                            </a>
                                        </>
                                        : <>
                                            <a className="button button--split button--split--vert" onClick={scrollToClick} href="#subscribe">
                                                <span className="button__arrow">&nbsp;</span>
                                                <span className="button__text">
                                                    {lang === "hu" ? <>Nyomod!</> : <>Let's go!</>}
                                                </span>
                                                <span className="button__anim">
                                                    <span>{lang === "hu" ? <>Feliratkozás</> : <>Subscribe</>}</span>
                                                    <span>{lang === "hu" ? <>Feliratkozás</> : <>Subscribe</>}</span>
                                                </span>
                                                <span className="button__placeholder">
                                                    {lang === "hu" ? <>Feliratkozás</> : <>Subscribe</>}
                                                </span>
                                            </a>
                                        </>
                                    }

                                </h1>
                            </div>
                        </div>
                    </section>
                    <section className="section no-padding--top">
                        <div className="container container--1000">
                            <div className="blog__posts">
                                {posts.map(({id, title_en, title_hu, date, description_hu, description_en, image}, index) => (
                                    <Link className="blog__post" key={index} to={`/${lang}/blog/${id}`}>
                                    <span className="blog__post-title">
                                        {lang === "hu"
                                            ? <>{title_hu}</>
                                            : <>{title_en}</>
                                        }
                                        <span className="blog__post-excerpt">
                                            {lang === "hu"
                                                ? <>{description_hu}</>
                                                : <>{description_en}</>
                                            }
                                        </span>
                                    </span>
                                    <span className="blog__post-meta">
                                        {date}
                                    </span>
                                    <span className="blog__post-read">
                                        {lang === "hu"
                                            ? <>Olvasás</>
                                            : <>Read</>
                                        }
                                    </span>
                                    <img className="blog__post-thumbnail" src={`${image}`} />
                                    </Link>
                                ))}
                                <div className="blog__post blog__post--more">
                                    {lang === "hu"
                                        ? <>Új bejegyzés #néha.</>
                                        : <>New post #sometimes.</>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    <div id="subscribe" className="blog__subscribe">
                        <div className="container container--700 blog__content">
                            <div className="container container--small padding-lg--bottom">
                                <SubscribeForm lang={lang}/>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </>
    )
}

export default Blog;
