import React from "react";
import { useParams } from "react-router-dom";

const Post5 = () => {
    const { id, lang } = useParams();

    return (
        <>
            {lang === "hu"
                ?
                <>
                    <article className="blog__article">
                        <p>Paramaguru Shri R. Sharath Jois szívrohamban meghalt egy túra közben Virginiában. Ez a tény
                            amitől
                            hánynom kell. Nálam sokkal értelmesebb emberek írtak búcsút GuruJi-hez, olyanok akik
                            évtizedek óta ismerték, tanítványon túl a barátai voltak. Én nem is kísérelném meg ezt, mert
                            Wade, Kino, Hamish és megannyian, csodásan öntötték szavakba Sharathji távozását.</p>
                        <p>Nem jógiknak célzom ezt az írást, hiszen ők a fenti neveket ismerik, látták a bejegyzéseket,
                            mást sem csinálnak mióta meghallották a hírt. Azoknak szeretnék segíteni megérteni mekkora
                            veszteség ez, akik nem értik, akik nem ismerték őt. Mondjuk a szüleimnek. És remélem azoknak is segít ez akik elnémultak a hír hallatán, és nem tudják szavakba önteni a fájdalmukat. Vagy azoknak akik
                            a guru szó hallattán csak gúnyosan mosolyognak. Nem bántásból mondom, mert egy vagyok
                            közülük. Vagyis hát egy voltam amíg nem mentem el Indiába.</p>
                        <p className="spotify box no-padding--top">
                            <iframe
                                src="https://open.spotify.com/embed/track/7FwBtcecmlpc1sLySPXeGE?utm_source=generator&theme=0"
                                width="100%" height="152" frameBorder="0" allowFullScreen=""
                                className="no-margin--top"
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                loading="lazy"></iframe>
                        </p>
                        <p>SharathJi sok tízezer órát töltött jóga gyakorlással és tanítással, amit a nagyapjától
                            örökölt mint életcél. Csak úgy képes ilyesmire az ember, ha szenvedéllyel áll hozzá.
                            SharathJi lényének minden atomjával a jóga volt. Lehetetlen volt a legszkeptikusabb
                            gúnyolódónak is (pl mint én) ezt nem érezni a közelében. Az iskolában töltött éveim alatt
                            egy tanárra sem emlékszem aki akár a kisújjáig felérne ahhoz ahogy a Gurum tanított. Nem
                            levitált a föld felett, nem varázsolt, egyszerű üzeneteket küldött de olyan energiával mint
                            egy napkitörés.</p>
                        <blockquote>Grhh you should relax, this is yoga, this is not wrestling… this should be fun<span>SharathJi miközben birkózunk</span>
                        </blockquote>
                        <p>Ezek a szavak olyan erőteljesek voltak abban a pillanatban amikor elhangoztak, hogy azóta is tisztán hallom őket, és átsegítettek rengeteg akadályon, csak a jóga szót kellett
                            kicserélnem. Ez nem arról szólt nála, hogy mennyire szépen tartassz ki egy pózt. Én amúgy
                            semennyire egyiket sem. Ahogy ezért sokszor kaptam is az ívet SharathJi-től. Ez egy terápia,
                            a legtisztább és iszonyat hatásos.</p>
                        <p>Ő nem egy hétvége alatt levizsgázott jóga tanár volt, viszont Mysore-ba nem azért mentünk
                            mert máshol nincsenek jó jóga tanárok. Azért mentünk, mert ő ott volt, és ő volt A tanár.
                            Egy világítótorony és kikötő a szartengeren, egy menedék amikor remeg a föld. Egy erő ami
                            lelassít és ráébreszt: ember vagy. Nem félisten, nem főnök, nem a fájdalmad, nem a munkád.
                            Ember. Egy vagy a természettel, egy vagy a légzéseddel.</p>
                        <p>A világ egyre sötétebb, egyre több zsarnok jelenik meg, élősködve a bizonytalanokon.
                            SharathJi ezt a bizonytalanságot egy belégzés-kilégzéssel eltűntette. Jobb emberré váltál
                            általa. Mindig kellenek a jobb emberek emiatt nagy veszteség ez. Azoknak is akik azt se
                            tudják ki volt ő. Százezrek, milliók gyászolják világszerte és Mysore-ban. Egy céllá vált
                            ember volt, aki köré egy várost emeltek, akihez alkalmazkodva éltek tízezrek.</p>
                        <p>Mindössze 53 éves volt. Bár volt a családjában hasonló eset, én a géneket nem tartom
                            felelősnek (de ez csak egy vélemény, nem vagyok orvos). Én annak akarom tudni, hogy
                            elajándékozta a szívét. Sokan akartunk belőle egy darabot és mindenkinek adott. Nem volt
                            irigy senkivel. Rengeteget adott vissza a városának, támogatott megannyi alapítványt. Volt
                            szerencsém vele elmenni egy rezervátumba, ahol vágóhídról mentett állatok éltek. Én
                            szerintem öt százalékát sem ismerem a nagyságának.</p>
                        <p>Az egyik nagy kérdés mi lesz most. Van ez a hatalmas közösség aki elvesztette a jelzőfényét.
                            Andrew Bresnen, mondta Austinban pár nappal később, hogy GuruJi eltávozott, hogy:</p>
                        <blockquote>Mintha olyan testvérekről lenne szó, hogy egyik három másik mondjuk hét, és
                            elvesztik a szüleiket. Az idősebb tesónak egyik percről a másikra fel kell nőnie, és
                            vigyázni a kicsire. De ő még nem áll készen, még csak gyerek. Erre nem állok készen…<span>Andrew Bresnen, PhD #thegreat</span>
                        </blockquote>
                        <p>Andrew egyébként elképesztő, és akad sok tanítványa SharathJi-nek akik az ő útját járják. Azt
                            szeretném hinni, hogy minden rendben lesz. Bár kurvára fáj ez az egész, megerősít. Minél
                            közelebb állunk a fényhez, minél vakítóbb, annál nagyobb árnyékot vetünk. Vagy máshogy a
                            sötét kell ahhoz, hogy értékelni tudjuk a fényt.</p>
                        <p>Remélem ez segít azoknak akik nem tudják miért sír a jógi barátjuk egy távoli, Indiai férfi
                            távozása miatt. Remélem ez segít megérteni, hogy ez a veszteség az emberiségnek hasonló mint
                            mikor egy géniusz mint például Einstein távozott. A fizikai teste után viszont egész biztos,
                            hogy a legjobb helyre került SharathJi, mi pedig akik itt maradtunk, szorítanunk kell a
                            reményt, mert az energia nem vész el csak átalakul.</p>
                        <h3 className="heading heading--3">Guru Brahma<br/>Guru Vishnu<br/>Guru Devo Maheshwara</h3>
                        <p className="box">
                            Ha van kedved pár vicces sztorit olvasni a tavalyi (2023) mysore-i kalandjaimról GuruJival, menj rá a "naplómra", amit "Cliché Misék" néven írtam.
                            <a href="/cliche-blog?lang=HU">Link a Cliché Misékhez</a>
                        </p>
                    </article>
                </>
                :
                <></>
            }
            {lang === "en"
                ?
                <>
                    <article className="blog__article">
                        <p>Paramaguru Shri R. Sharath Jois passed away from a heart attack during a hike in Virginia.
                            Just stating this fact makes me feel sick. Far wiser and more experienced people than I have
                            already written their goodbyes to GuruJi—people who knew him for decades and were not just
                            students but friends. I wouldn’t even attempt to match those tributes—Wade, Kino, Hamish,
                            and many others have beautifully put into words what SharathJi’s departure means.</p>
                        <p>This writing isn’t for yogis. They know these names, they’ve seen the posts, and they’ve done
                            little else since hearing the news. I want to help those who don’t understand the scale of
                            this loss—those who didn’t know him. People like my parents, perhaps. And I hope it also helps those who were struck silent upon hearing the news, unable to put their pain into words. Or those who can’t
                            help but smirk at the word “guru.” I don’t mean this as an insult—I used to be one of them.
                            At least until I went to India.</p>
                        <p className="spotify box no-padding--top">
                            <iframe
                                src="https://open.spotify.com/embed/track/7FwBtcecmlpc1sLySPXeGE?utm_source=generator&theme=0"
                                width="100%" height="152" frameBorder="0" allowFullScreen=""
                                className="no-margin--top"
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                loading="lazy"></iframe>
                        </p>
                        <p>SharathJi spent tens of thousands of hours practicing and teaching yoga, carrying on the
                            life’s work he inherited from his grandfather. You can only do something like this if you
                            approach it with complete passion. SharathJi embodied yoga in every fiber of his being. Even
                            the most skeptical person (like me) couldn’t help but feel that energy when they were near
                            him. Throughout my years in school, I never had a single teacher who came close to what he
                            was as a teacher. He didn’t levitate off the ground or perform miracles; his messages were
                            simple, but they carried the force of a solar flare.</p>
                        <blockquote>Grhh you should relax, this is yoga, this is not wrestling… this should be fun<span>SharathJi while wrestling with me</span>
                        </blockquote>
                        <p>These words were so powerful when he said them that I can still hear them clearly in my mind. They helped me overcome countless challenges—I only had to replace the
                            word “yoga” with whatever I was struggling with. Being with him wasn’t about perfecting a
                            pose. I never managed to perfect any of them, and he didn’t shy away from letting me know
                            that. But that wasn’t the point. It was therapy—the purest and most effective kind.</p>
                        <p>He wasn’t a yoga teacher who earned his certification over a weekend. And yet, we didn’t go
                            to Mysore because there are no good yoga teachers elsewhere. We went because he was there.
                            He was the teacher. A lighthouse on a sea during shit storm, a safe harbor when the ground
                            was shaking. A force that slowed you down and reminded you: you are human. Not a demigod,
                            not a boss, not your pain, not your job. Human. You are one with nature, one with your
                            breath.</p>
                        <p>The world keeps getting darker, with more and more tyrants preying on the uncertain.
                            SharathJi could make that uncertainty disappear with just one inhale and exhale. He made you
                            a better person. And the world always needs better people—that’s why this is such a great
                            loss, even for those who didn’t know him. Hundreds of thousands, perhaps millions, are
                            mourning him around the world and in Mysore. He was a man who became his purpose, someone
                            around whom a city was built and who influenced tens of thousands of lives.</p>
                        <p>He was only 53. While there was a similar history in his family, I don’t believe genetics
                            were to blame (but that’s just my opinion; I’m not a doctor). I prefer to think it’s because
                            he gave away his heart. So many of us wanted a piece of it, and he gave it to everyone. He
                            was never stingy. He gave back so much to his city and supported countless charities. I was
                            lucky enough to visit a cow sanctuary with him, a place where animals rescued from
                            slaughterhouses were given a home. I feel I only understood a fraction of his greatness.</p>
                        <p>One of the big questions now is: What happens next? This massive community has lost its north
                            star, its guiding light. Andrew Bresnen said just a few days later in Austin that GuruJi’s
                            passing feels like:</p>
                        <blockquote>Imagine siblings—one is three years old, another seven—and they lose their parents.
                            Suddenly, the older sibling has to grow up overnight and take care of the younger one. But
                            they’re not ready; they’re still just a kid. I’m not ready for this…<span>Andrew Bresnen, PhD #thegreat</span>
                        </blockquote>
                        <p>Andrew is incredible, and many of SharathJi’s students are walking in his footsteps. I want
                            to believe that everything will be okay. Even though this hurts like hell, it strengthens
                            us. The closer we are to the light, the more dazzling it is, the deeper the shadow we cast.
                            Or, to put it another way, we need the darkness to truly appreciate the light.</p>
                        <p>I hope this helps those who don’t understand why their yogi friend is grieving the loss of a
                            distant Indian man. I hope this helps them see that this loss for humanity is like when a
                            genius like Einstein passed away. But I believe SharathJi, in leaving his physical body, has
                            gone to the best possible place. And for those of us still here, we must hold on to hope,
                            because energy never disappears—it only transforms.</p>
                        <h3 className="heading heading--3">Guru Brahma<br/>Guru Vishnu<br/>Guru Devo Maheshwara</h3>
                        <p className="box">
                            If you’re up for some funny stories about my time in Mysore last year (2023) with GuruJi, check out my little journal, "Cliché Tales".
                            <a href="/cliche-blog">Link to the Cliché Tales</a>
                        </p>
                    </article>
                </>
                :
                <></>
            }
            {lang === "es"
                ?
                <>
                    <article className="blog__article">
                        <p>Paramaguru Shri R. Sharath Jois falleció de un ataque al corazón durante una caminata en Virginia. El solo hecho de decirlo me enferma. Personas mucho más sabias y experimentadas que yo ya han escrito sus despedidas a GuruJi, personas que lo conocieron durante décadas y que no eran solo estudiantes, sino amigos. Yo ni siquiera intentaría igualar esos homenajes: Wade, Kino, Hamish y muchos otros han expresado maravillosamente con palabras lo que significa la partida de SharathJi.</p>
                        <p>Este escrito no es para yoguis. Ellos conocen estos nombres, han visto las publicaciones y no han hecho mucho más desde que escucharon la noticia. Quiero ayudar a quienes no entienden la magnitud de esta pérdida, a quienes no lo conocieron. Personas como mis padres, tal vez. Y espero que también ayude a quienes se quedaron en silencio al escuchar la noticia, incapaces de expresar su dolor con palabras. O a quienes no pueden evitar sonreír ante la palabra "gurú". No lo digo como un insulto: yo solía ser uno de ellos. Al menos hasta que fui a India.</p>
                        <p className="spotify box no-padding--top">
                            <iframe
                                src="https://open.spotify.com/embed/track/7FwBtcecmlpc1sLySPXeGE?utm_source=generator&theme=0"
                                width="100%" height="152" frameBorder="0" allowFullScreen=""
                                className="no-margin--top"
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                loading="lazy"></iframe>
                        </p>
                        <p>SharathJi pasó decenas de miles de horas practicando y enseñando yoga, continuando el trabajo de toda una vida que heredó de su abuelo. Solo puedes hacer algo así si lo abordas con completa pasión. SharathJi encarnaba el yoga en cada fibra de su ser. Incluso la persona más escéptica (como yo) no podía evitar sentir esa energía cuando estaba cerca de él. A lo largo de mis años en la escuela, nunca tuve un solo profesor que se acercara a lo que él era como maestro. No levitaba del suelo ni hacía milagros; sus mensajes eran sencillos, pero llevaban la fuerza de una llamarada solar.</p>
                        <blockquote>Grhh, debes relajarte. Esto es yoga, no lucha libre… esto debería ser divertido.<span>SharathJi mientras luchaba conmigo</span></blockquote>
                        <p>Estas palabras fueron tan poderosas cuando las dijo que podía escucharlas claramente en mi mente durante un año después. Me ayudaron a superar innumerables desafíos: solo tenía que reemplazar la palabra “yoga” por cualquier cosa con la que estuviera luchando. Estar con él no se trataba de perfeccionar una postura. Nunca logré perfeccionar ninguna, y él no evitaba hacérmelo saber. Pero ese no era el punto. Era terapia, la más pura y efectiva.</p>
                        <p>Él no era un maestro de yoga que obtuvo su certificación en un fin de semana. Y, sin embargo, no íbamos a Mysore porque no hubiera buenos maestros de yoga en otros lugares. Íbamos porque él estaba allí. Él era el maestro. Un faro en medio de una tormenta de mierda, un puerto seguro cuando el suelo temblaba. Una fuerza que te desaceleraba y te recordaba: eres humano. No un semidiós, no un jefe, no tu dolor, no tu trabajo. Humano. Eres uno con la naturaleza, uno con tu respiración.</p>
                        <p>El mundo sigue oscureciéndose, con más y más tiranos que se aprovechan de los inseguros. SharathJi podía hacer desaparecer esa incertidumbre con solo una inhalación y una exhalación. Te hacía una mejor persona. Y el mundo siempre necesita mejores personas; por eso esta es una gran pérdida, incluso para quienes no lo conocieron. Cientos de miles, quizá millones, lo están llorando en todo el mundo y en Mysore. Fue un hombre que se convirtió en su propósito, alguien alrededor de quien se construyó una ciudad y que influyó en decenas de miles de vidas.</p>
                        <p>Tenía solo 53 años. Aunque en su familia había antecedentes similares, no creo que la genética sea la culpable (pero esa es solo mi opinión; no soy médico). Prefiero pensar que es porque entregó su corazón. Tantos de nosotros queríamos un pedazo de él, y se lo dio a todos. Nunca fue tacaño. Devolvió mucho a su ciudad y apoyó innumerables organizaciones benéficas. Tuve la suerte de visitar un santuario de vacas con él, un lugar donde los animales rescatados de mataderos encontraban un hogar. Siento que solo entendí una fracción de su grandeza.</p>
                        <p>Una de las grandes preguntas ahora es: ¿qué pasa después? Esta enorme comunidad ha perdido su estrella del norte, su luz guía. Andrew Bresnen, PhD, dijo solo unos días después en Austin que la partida de GuruJi se siente como:</p>
                        <blockquote>Imaginen a dos hermanos: uno tiene tres años, el otro siete, y pierden a sus padres. De repente, el mayor tiene que crecer de la noche a la mañana y hacerse cargo del menor. Pero no está preparado; todavía es solo un niño. Yo no estoy preparado para esto…<span>Andrew Bresnen, PhD #elgrande</span></blockquote>
                        <p>Andrew es increíble, y muchos de los estudiantes de SharathJi están siguiendo sus pasos. Quiero creer que todo estará bien. Aunque esto duela como el infierno, nos fortalece. Cuanto más cerca estamos de la luz, más deslumbrante es y más profunda es la sombra que proyectamos. O, dicho de otra manera, necesitamos la oscuridad para realmente apreciar la luz.</p>
                        <p>Espero que esto ayude a quienes no entienden por qué su amigo yogui está de luto por la pérdida de un hombre indio lejano. Espero que esto les ayude a ver que esta pérdida para la humanidad es como cuando un genio como Einstein falleció. Pero creo que SharathJi, al dejar su cuerpo físico, se ha ido al mejor lugar posible. Y para los que seguimos aquí, debemos aferrarnos a la esperanza, porque la energía nunca desaparece, solo se transforma.</p>
                        <h3 className="heading heading--3">Guru Brahma<br/>Guru Vishnu<br/>Guru Devo Maheshwara</h3>
                        <p className="box">
                            If you’re up for some funny stories about my time in Mysore last year (2023) with GuruJi, check out my little journal, "Cliché Tales".
                            <a href="/cliche-blog">Link to the Cliché Tales</a>
                        </p>
                    </article>
                </>
                :
                <></>
            }
        </>
    );
}

export default Post5;
