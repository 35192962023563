import React, {useRef} from 'react';
import {motion, useInView} from "framer-motion";
import {isMobile} from 'react-device-detect';

import {verticalScrollOffsetMarginBase} from "../../../transitions/TransitionConstants";

interface Props {
    title: string;
    classList: string;
}

function Spotify({song, classList}) {
    const ref = useRef(null);
    const isInView = useInView(ref, {margin: verticalScrollOffsetMarginBase, once: !!isMobile});

    return (
        <motion.section
            className={`${classList ? 'spotify box no-padding--top ' + classList : 'spotify box no-padding--top'}`}>
            <iframe
                ref={ref}
                style={{
                    opacity: isInView ? 1 : 0,
                    transition: "all 300ms ease-in-out"
                }}
                src={`${'https://open.spotify.com/embed/track/' + song + '?utm_source=generator&theme=0'}`}
                width="100%" height="152" frameBorder="0" allowFullScreen=""
                className="no-margin--top"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"></iframe>
        </motion.section>
    );
}

export default Spotify;
